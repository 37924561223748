import { css } from 'styled-components';

export const Theme = css`
  /* Violet color for light color scheme (Default) */
  /* Can be forced with data-theme="light" */

  [data-theme=light],
  :root:not([data-theme=dark]) {
    --pico-text-selection-color: rgba(167, 128, 212, 0.25);
    --pico-primary: #8352c5;
    --pico-primary-background: #7540bf;
    --pico-primary-underline: rgba(131, 82, 197, 0.5);
    --pico-primary-hover: #6935b3;
    --pico-primary-hover-background: #6935b3;
    --pico-primary-focus: rgba(167, 128, 212, 0.25);
    --pico-primary-inverse: #fff;
  }

  /* Violet color for dark color scheme (Auto) */
  /* Automatically enabled if user has Dark mode enabled */
  @media only screen and (prefers-color-scheme: dark) {
    :root:not([data-theme]) {
      --pico-text-selection-color: rgba(178, 144, 217, 0.1875);
      --pico-primary: #b290d9;
      --pico-primary-background: #7540bf;
      --pico-primary-underline: rgba(178, 144, 217, 0.5);
      --pico-primary-hover: #c9afe4;
      --pico-primary-hover-background: #8352c5;
      --pico-primary-focus: rgba(178, 144, 217, 0.25);
      --pico-primary-inverse: #fff;
    }
  }

  /* Violet color for dark color scheme (Forced) */
  /* Enabled if forced with data-theme="dark" */

  [data-theme=dark] {
    --pico-text-selection-color: rgba(178, 144, 217, 0.1875);
    --pico-primary: #b290d9;
    --pico-primary-background: #7540bf;
    --pico-primary-underline: rgba(178, 144, 217, 0.5);
    --pico-primary-hover: #c9afe4;
    --pico-primary-hover-background: #8352c5;
    --pico-primary-focus: rgba(178, 144, 217, 0.25);
    --pico-primary-inverse: #fff;
  }

  /* Violet (Common styles) */

  :root {
    --form-element-active-border-color: var(--primary);
    --form-element-focus-color: var(--primary-focus);
    --switch-color: var(--primary-inverse);
    --switch-checked-background-color: var(--primary);
  }
`;
