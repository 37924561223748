import '@picocss/pico/css/pico.min.css';
import './Assets/Fonts/readex-pro/stylesheet.css';
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { Application } from './System/Application';
import styled, { createGlobalStyle } from 'styled-components';
// @ts-ignore
import Logo from 'url:./Assets/Images/Logo Color Square BG 2023.png';
import { TypeStructureMap } from './System/TypeParsing/TypeUtils';
import { convertTypeScriptToTypeStructureMap } from './System/TypeParsing/TypeParsing';
import { Theme } from './Assets/Theme';
// @ts-ignore
import HelperTypesScript from 'bundle-text:./System/HelperTypes.txt';
import { Editor } from './System/Editor';
import { getLocalJSON, LocalJSON } from './System/Storage/LocalJSON';

const CODE_STORE: LocalJSON = getLocalJSON('CODE_STORE');
const FORM_CODE = CODE_STORE.read('FORM_CODE');
const TYPE_SELECTION = CODE_STORE.read('TYPE_SELECTION');
const FORM_VALUE = CODE_STORE.read('FORM_VALUE');

const HelperTypeMap = convertTypeScriptToTypeStructureMap(HelperTypesScript);
const HelperTypeList = Object.keys(HelperTypeMap);

const LogoImage = styled.img`
  flex: 0 0 auto;
  height: 3em;
  border-radius: var(--border-radius);
`;

const GlobalStyle: FC = createGlobalStyle`
  html,
  body {
    margin: 0;
    width: 100vw;
    height: 100vh;
  }

  input,
  label,
  button {
    margin-bottom: 0;
  }

  input[type="checkbox"] {
    margin-bottom: -0.5em;
  }
` as any;

const AppBase = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap: 1em;

  width: 100vw;
  height: 100vh;

  box-sizing: border-box;
  padding: 1em;

  overflow: hidden;

  ${Theme}
`;
const HeaderBox = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: 1em;
`;
const AppBody = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: stretch;
  align-items: stretch;
  box-sizing: border-box;
  gap: 1em;
  overflow: hidden;
`;
const StackBox = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap: 1em;
  overflow: auto;
  width: auto;
  height: auto;
`;
const TopStackBox = styled(StackBox)`
  justify-content: flex-start;
`;
const StackBoxRow = styled(StackBox)`
  flex-direction: row;
`;
const StackBoxControls = styled(StackBoxRow)`
  flex: 0 0 auto;
`;
const ControlButton = styled.button`
  flex: 1 0 auto;
  padding: 0.25em;
`;

export const App: FC = () => {
  const [value, setValueInternal] = useState<any>(FORM_VALUE);
  const setValue = useCallback((newValue: any) => {
    CODE_STORE.create('FORM_VALUE', newValue);
    setValueInternal(newValue);
  }, []);
  const [typeScript, setTypeScriptInternal] = useState<string>(FORM_CODE);
  const setTypeScript = useCallback((newValue: any) => {
    CODE_STORE.create('FORM_CODE', newValue);
    setTypeScriptInternal(newValue);
  }, []);
  const typeStructureMap = useMemo<TypeStructureMap>(() => {
    try {
      return convertTypeScriptToTypeStructureMap(`
        ${HelperTypesScript}
        
        ${typeScript}
      `);
    } catch (e) {
      return {};
    }
  }, [typeScript]);
  const [showingHelperTypes, setShowingHelperTypes] = useState<boolean>(false);
  const onShowHelperTypes = useCallback(() => {
    setShowingHelperTypes(true);
  }, []);
  const onHideHelperTypes = useCallback(() => {
    setShowingHelperTypes(false);
  }, []);
  const typeList = useMemo(() => Object.keys(typeStructureMap), [typeStructureMap]);
  const userOnlyTypes = useMemo(() => typeList.filter((type) => !HelperTypeList.includes(type)), [typeList]);
  const [selectedType, setSelectedTypeInternal] = useState<string>(TYPE_SELECTION);
  const setSelectedType = useCallback((newValue: string) => {
    CODE_STORE.create('TYPE_SELECTION', newValue);
    setSelectedTypeInternal(newValue);
  }, []);
  const hasSelectedType = useMemo(
    () => typeList.length > 0 && !!selectedType && typeList.includes(selectedType),
    [typeList, selectedType]
  );
  const onSelectedTypeChange = useCallback(
    ({ target: { value: newValue = '' } }: ChangeEvent<HTMLSelectElement>) => {
      setSelectedType(newValue);
      setValue(undefined);
    },
    [setSelectedType, setValue]
  );

  return (
    <AppBase>
      <GlobalStyle />
      <HeaderBox>
        <LogoImage src={Logo} title="Voltra" />
      </HeaderBox>
      <AppBody>
        {/*TODO: Maybe a lightweight code editor here*/}
        <StackBox>
          <small>
            IMPORTANT: Only types with the <code>export</code> keyword are available for selection.
          </small>
          <StackBox>
            <StackBoxControls>
              <ControlButton
                className={!showingHelperTypes ? 'outline' : 'secondary outline'}
                onClick={onHideHelperTypes}
              >
                Code
              </ControlButton>
              <ControlButton
                className={showingHelperTypes ? 'outline' : 'secondary outline'}
                onClick={onShowHelperTypes}
              >
                Available Helper Types
              </ControlButton>
            </StackBoxControls>
            {!showingHelperTypes ? (
              <Editor value={typeScript} onChange={setTypeScript} />
            ) : (
              <Editor value={HelperTypesScript} readOnly />
            )}
          </StackBox>
        </StackBox>
        <TopStackBox>
          <select value={selectedType} onChange={onSelectedTypeChange}>
            <option value="">Select a type</option>
            {userOnlyTypes.map((type) => (
              <option key={type} value={type} onClick={() => setSelectedType(type)}>
                {type}
              </option>
            ))}
          </select>
          {hasSelectedType ? (
            <Application
              typeStructureMap={typeStructureMap}
              value={value}
              entryType={selectedType}
              onChange={setValue}
            />
          ) : undefined}
        </TopStackBox>
        <pre>
          <code>{JSON.stringify(value, null, 2)}</code>
        </pre>
      </AppBody>
    </AppBase>
  );
};
